import React from 'react';
// import ReactDOM from 'react-dom';

// @ts-ignore
const template = document.createElement('template');

template.innerHTML = `
  <style>
   .button-container {
      text-align: center;
      display: flex;
      justify-content: center;
      place-items: center;
    }
    
    .button {
      /*display: block;*/
      overflow: hidden;
      position: relative;
      padding: 3px 20px;
      /*font-size: 16px;*/
      /*font-weight: bold;*/
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      outline: none;
      text-align: center;
      background-color: #577eff;
      color: #fff;
      border-radius: 30px;
      border: 0 none;
      /*width: 100%;*/
      /*height: 40px;*/
    }
  </style>

  <div class="container">
    <button class="button">Label</button>
  </div>
`;

class XButton extends HTMLElement {
  private $container: any;
  private $button: HTMLButtonElement | null;
  private root: ShadowRoot;

  constructor() {
    super();

    this.root = this.attachShadow({ mode: 'open' });
    this.root.appendChild(template?.content?.cloneNode(true));

    this.$container = this.root.querySelector('.container');
    this.$button = this.root.querySelector('button');

    this.$button!.addEventListener('click', () => {
      console.log('on click x-button');

      // ReactDOM.render(<p>1111111</p>, this.$container);
      // this.dispatchEvent(
      //   new CustomEvent('onClick', {
      //     detail: 'Hello from within the Custom Element',
      //   }),
      // );
    });

    this.$button!.addEventListener('blur', () => {
      console.log('on blur');

      // ReactDOM.render(<p>1111111</p>, this.$container);
      // this.dispatchEvent(
      //   new CustomEvent('onClick', {
      //     detail: 'Hello from within the Custom Element',
      //   }),
      // );
    });
  }

  connectedCallback() {
    if (this.hasAttribute('as-atom')) {
      this.updateAsAtom();
    }
  }

  updateAsAtom() {
    this.$container.style.padding = '0px';
  }

  get label() {
    return this.getAttribute('label');
  }

  set label(value) {
    if (typeof value === 'string') {
      this.setAttribute('label', value);
    }
  }

  static get observedAttributes() {
    return ['label'];
  }

  attributeChangedCallback() {
    this.render();
  }

  render() {
    this.$button!.innerHTML = this.label as string;
  }
}

const customElementName = 'x-button';
if (!customElements.get(customElementName)) customElements.define(customElementName, XButton);
