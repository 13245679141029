import '@vaadin/vaadin-time-picker/vaadin-time-picker';
import React from 'react';
// import ReactDOM from 'react-dom';

// @ts-ignore
const template = document.createElement('template');

template.innerHTML = `
  <style>
   .button-container {
      /*text-align: center;*/
      display: flex;
      /*justify-content: center;*/
      place-items: center;
    }
  </style>

  <div class="container">
    <vaadin-time-picker value="10:10" clear-button-visible></vaadin-time-picker>
    <vaadin-time-picker value="11:11" clear-button-visible></vaadin-time-picker>
    <vaadin-time-picker value="11:11" clear-button-visible></vaadin-time-picker>
    <vaadin-time-picker value="11:11" clear-button-visible></vaadin-time-picker>
    <vaadin-time-picker value="11:11" clear-button-visible></vaadin-time-picker>
    <vaadin-time-picker value="11:11" clear-button-visible></vaadin-time-picker>
    <vaadin-time-picker value="11:11" clear-button-visible></vaadin-time-picker>
    <vaadin-time-picker value="11:11" clear-button-visible></vaadin-time-picker>
    <vaadin-time-picker value="11:11" clear-button-visible></vaadin-time-picker>
    <vaadin-time-picker value="11:11" clear-button-visible></vaadin-time-picker>
  </div>
`;

class XTime extends HTMLElement {
  private $container: any;
  private root: ShadowRoot;

  constructor() {
    super();

    this.root = this.attachShadow({ mode: 'open' });
    this.root.appendChild(template?.content?.cloneNode(true));

    this.$container = this.root.querySelector('.container');
  }

  connectedCallback() {}

  attributeChangedCallback() {
    this.render();
  }

  render() {
    // this.$button!.innerHTML = this.label as string;
  }
}

const customElementName = 'x-time';
if (!customElements.get(customElementName)) customElements.define(customElementName, XTime);
