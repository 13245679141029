import { adapter } from '@/components/Grid/adapter';
import '@vaadin/vaadin-button/vaadin-button';
import '@vaadin/vaadin-select/vaadin-select';
//
import '@/web-components/x-button/x-button';
import '@/web-components/v-button/v-button';
import '@/web-components/x-time/x-time';
import '@/web-components/x-time5/x-time5';

export function generateDataRows(rowsNumber: number, colsNumber: number): { [key: string]: string }[] {
  const result: { [key: string]: string }[] = [];
  const all = colsNumber * rowsNumber;
  for (let j = 0; j < all; j++) {
    let col = j % colsNumber;
    let row = (j / colsNumber) | 0;
    if (!result[row]) {
      result[row] = {};
    }

    result[row][col] = row + ':' + col;
  }
  return result;
}

export function generateHeader(index: number) {
  const asciiFirstLetter = 65;
  const lettersCount = 26;
  let div = index + 1;
  let label = '';
  let pos;

  while (div > 0) {
    pos = (div - 1) % lettersCount;
    label = String.fromCharCode(asciiFirstLetter + pos) + label;
    div = parseInt(((div - pos) / lettersCount).toString(), 10);
  }

  console.log(label);
  if (label === 'C') return 'Button-10';
  if (label === 'M') return 'Time-5';
  if (label === 'X') return 'Time-10';

  return label;
}

export function generateFakeDataObject(rowsNumber: number, colsNumber: number) {
  const result = [];
  const columns = {};
  const all = colsNumber * rowsNumber;

  for (let j = 0; j < all; j++) {
    let col = j % colsNumber;
    let row = (j / colsNumber) | 0;

    if (!result[row]) {
      result[row] = {};
    }

    // @ts-ignore
    if (!columns[col]) {
      // @ts-ignore
      columns[col] = {
        name: generateHeader(col),
        prop: col,
        // pin: j === 0 ? 'colPinStart' : j === 20 ? 'colPinEnd' : undefined,
        pin: j === 0 ? 'colPinStart' : undefined,
        sortable: true,
        // use React Component
        // cellTemplate(h: any, p: any) {
        //   if (j === 5) {
        //     return h('span', {
        //       ref: (el: any) => adapter(el, p),
        //     });
        //   }
        //
        //   return undefined;
        // },
        // use WC
        editor: j === 1 || j === 4 ? 'x-button-editor' : undefined,
        cellTemplate: (createElement: any, props: any) => {
          // @ts-ignore
          if (columns[col].name === 'B' || columns[col].name === 'E') {
            return createElement(
              'x-button',
              {
                label: `${props.model[props.prop]}-${col}`,
              },
              `BTN-${props.model[props.prop]}-${col}`,
            );
          }

          if ((col > 20 && col % 10 === 0) || (col > 50 && col < 80)) {
            return createElement(
              'vaadin-button',
              {
                style: {
                  color: 'blue',
                },
                active: true,
              },
              `BTN-${props.model[props.prop]}-${col}`,
            );
          }

          if (colsNumber - col < 10) {
            return createElement(
              'span',
              {
                ref: (el: any) => {
                  // console.log('adapter', el, props);
                  // console.log(el);

                  // console.log(props);
                  // column: {name: "gn", prop: 195, pin: undefined, sortable: true, editor: undefined, …}
                  // data: (50000) [{…}, {…}]
                  // model: {0: 102, 1: 102, 2: 102, 3: 102}
                  // prop: 195
                  // rowIndex: 102

                  adapter(el, props);
                },
              },
              `RC-${props.model[props.prop]}`,
            );
          }

          if (j === 3 || (j > 15 && j < 20)) {
            const imgId = () => {
              let s = j % 2 === 0 ? 'women' : 'men';
              let n = props.model[props.prop];

              if (n > 99) n = n & 99;

              return {
                s,
                n,
              };
            };

            return createElement('img', {
              // @ts-ignore
              src: `https://randomuser.me/api/portraits/${imgId().s}/${imgId().n}.jpg`,
              width: 30,
              height: 30,
            });
          }

          // @ts-ignore
          // if (columns[col].name === 'C') {
          if (columns[col].name === 'Button-10') {
            return createElement('v-button', {
              title: 'button',
            });
          }

          // @ts-ignore
          // if (columns[col].name === 'M') {
          if (columns[col].name === 'Time-5') {
            return createElement('x-time5', {});
          }

          // @ts-ignore
          // if (columns[col].name === 'X') {
          if (columns[col].name === 'Time-10') {
            return createElement('x-time', {});
          }

          // @ts-ignore
          if (columns[col].name === 'H') {
            return createElement(
              'span',
              {},
              // @ts-ignore
              `Long Text ${columns[col].name.toLocaleUpperCase()} ${columns[col].prop} Text ${columns[
                col
                // @ts-ignore
                // eslint-disable-next-line max-len
              ].name.toLocaleUpperCase()} 😃 Smileys & People 🐻 Animals & Nature 🍔 Food & Drink ⚽ Activity 🌇 Travel & Places 💡 Objects 🔣 Symbols 🎌 Flags ✅  اَلْعَرَبِيَّةُ 表情 `,
            );
          }

          // @ts-ignore
          return `Text ${columns[col].name.toLocaleUpperCase()} ${props.model[props.prop]}-${j} ✅`;
        },
      };
    }

    // @ts-ignore
    result[row][col] = row;

    if (col === 5) {
      // @ts-ignore
      columns[col] = {
        // @ts-ignore
        ...columns[col],
        autoSize: true,
      };
    }
  }

  const pinnedTopRows = (result[10] && [result[10]]) || [];
  const pinnedBottomRows = (result[1] && [result[1]]) || [];

  // @ts-ignore
  let headers = Object.keys(columns).map((k) => columns[k]);

  // const grouped = headers.splice(1, 4);
  // const grouped2 = grouped.splice(0, 2);
  // grouped.push({
  //   name: 'Grouped2',
  //   children: grouped2,
  // });
  // headers.splice(
  //   6,
  //   0,
  //   ...[
  //     {
  //       name: 'Grouped',
  //       children: grouped,
  //     },
  //   ],
  // );
  // const grouped4 = headers.splice(1, 3);
  // headers.splice(
  //   1,
  //   0,
  //   ...[
  //     {
  //       name: 'Grouped3',
  //       children: grouped4,
  //     },
  //   ],
  // );

  return {
    source: result,
    pinnedTopRows,
    pinnedBottomRows,
    columns: headers,
  };
}
