import React, { useState } from 'react';
import { Button, InputNumber, Input } from 'antd';
import { XButtonEditor } from './XButtonEditorFn';

import { defineCustomElements } from '@revolist/revogrid/loader'; // webcomponent definition loader
import { RevoGrid } from '@revolist/revogrid-react';

import { generateFakeDataObject } from '@/utils/mock';

import styles from './styles-less.module.less';
// import { adapter } from '@/components/Grid/adapter';

defineCustomElements();

const initRowsNumber = 50000;
const initColsNumber = 200;
const initGridWidth = 1000;
const initGridHeight = 800;
const colSize = 200;
const rowSize = 32;

const initData = generateFakeDataObject(initRowsNumber, initColsNumber);

export default function Grid() {
  const [rowsNumber, setRowsNumber] = useState(initRowsNumber);
  const [colsNumber, setColsNumber] = useState(initColsNumber);
  //
  const [gridWidth, setGridWidth] = useState(initGridWidth);
  const [gridHeight, setGridHeight] = useState(initGridHeight);
  const [data, setData] = useState(initData);
  // const data = useMemo(() => generateFakeDataObject(rowsNumber, colsNumber), [rowsNumber, colsNumber]);

  // const afterEdit = ({ detail }: any) => {
  //   console.log('afterEdit', detail);
  // };

  // useEffect(() => {});

  const onReRender = () => {
    console.log('onReRender');
    setData(generateFakeDataObject(rowsNumber, colsNumber));
  };

  return (
    <div className={styles['comp-wrapper']}>
      <div className={styles['header-wrapper']}>
        <div className={styles['tips']}>
          <ul>
            <li>
              last 10 columns are <code>React Components</code>
            </li>
            <li>
              50-80 columns are <code>Web Components</code>
            </li>
            <li>
              other columns are <code>Text</code>
            </li>
          </ul>
        </div>

        <div className={styles['info']}>
          <div>
            <span>gridW: </span> <InputNumber value={gridWidth} onChange={(v) => setGridWidth(v as number)} />
            <span>gridH: </span> <InputNumber value={gridHeight} onChange={(v) => setGridHeight(v as number)} />
          </div>
          <div>
            <span>rows: </span> <InputNumber value={rowsNumber} onChange={(v) => setRowsNumber(v as number)} />
            <span>cols: </span> <InputNumber value={colsNumber} onChange={(v) => setColsNumber(v as number)} />
            <Button onClick={onReRender}>ReRender</Button>
          </div>
        </div>
      </div>

      <RevoGrid
        colSize={colSize}
        rowSize={rowSize}
        columns={data.columns}
        source={data.source}
        // onAfterEdit={afterEdit}
        className={styles['revogrid']}
        style={{ width: gridWidth, height: gridHeight }}
        range
        onCopyCapture={(e) => console.log(e)}
        onPaste={(e) => console.log(e)}
        resize
        pinnedTopSource={data.pinnedTopRows}
        editors={{
          // @ts-ignore
          'x-button-editor': XButtonEditor,
        }}
      />
    </div>
  );
}
