import React from 'react';
import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';

import styles from './styles-less.module.less';

interface IProps {
  val: any;
  //
  className?: string;
  style?: React.CSSProperties;
}

export default function Cell(props: IProps) {
  return (
    <div className={styles['comp-wrapper']}>
      <Switch defaultChecked={props.val % 8 === 0} /> {props.val}
    </div>
  );
}
