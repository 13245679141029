import React from 'react';
import ReactDOM from 'react-dom';

import Cell from './Cell';

interface IProps {
  model: any;
  prop: any;
  //
  className?: string;
  style?: React.CSSProperties;
}

export function adapter(parent: any, props: IProps) {
  let wrapper;

  if (parent?.childNodes?.length) {
    wrapper = parent.childNodes[0];
    if (!wrapper) return null;

    ReactDOM.unmountComponentAtNode(wrapper);
  } else {
    wrapper = document.createElement('span');
    if (!wrapper) return null;

    parent?.appendChild(wrapper);
  }

  ReactDOM.render(<Cell val={props.model[props.prop]} /> || null, wrapper);
}
