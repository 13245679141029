import '@vaadin/vaadin-button/vaadin-button';
import React from 'react';
// import ReactDOM from 'react-dom';

// @ts-ignore
const template = document.createElement('template');

template.innerHTML = `
  <style>
   .button-container {
      text-align: center;
      display: flex;
      justify-content: center;
      place-items: center;
    }
    
    .v-button {
      display: inline-block;
    }
    
    :host {
      .v-button .vaadin-button-container button {
        padding: 2px 3px !important;
      }
    }
    
    
    button, 
    .v-button {
      height: 20px;
      line-height: 20px;
      
    }
  </style>

  <div class="container">
    <vaadin-button theme="contrast primary small" class="v-button">B1</vaadin-button>
    <vaadin-button theme="success primary small" class="v-button">B2</vaadin-button>
    <vaadin-button theme="success primary small" class="v-button">B3</vaadin-button>
    <vaadin-button theme="success primary small" class="v-button">B4</vaadin-button>
    <vaadin-button theme="success primary small" class="v-button">B5</vaadin-button>
    <vaadin-button theme="success primary small" class="v-button">B6</vaadin-button>
    <vaadin-button theme="success primary small" class="v-button">B7</vaadin-button>
    <vaadin-button theme="success primary small" class="v-button">B8</vaadin-button>
    <vaadin-button theme="success primary small" class="v-button">B9</vaadin-button>
    <vaadin-button theme="success primary small" class="v-button">B10</vaadin-button>
  </div>
`;

class VButton extends HTMLElement {
  private $container: any;
  private root: ShadowRoot;

  constructor() {
    super();

    this.root = this.attachShadow({ mode: 'open' });
    this.root.appendChild(template?.content?.cloneNode(true));

    this.$container = this.root.querySelector('.container');
  }

  connectedCallback() {}

  attributeChangedCallback() {
    this.render();
  }

  render() {
    // this.$button!.innerHTML = this.label as string;
  }
}

const customElementName = 'v-button';
if (!customElements.get(customElementName)) customElements.define(customElementName, VButton);
