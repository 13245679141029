import React from 'react';
import Grid from '@/components/Grid/Grid';

function App() {
  return (
    <div className="App">
      <Grid />
    </div>
  );
}

export default App;
