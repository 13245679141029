import React from 'react';
// import ReactDOM from 'react-dom';
// import { adapter } from '@/components/Grid/adapter';
import { RevoGrid } from '@revolist/revogrid/dist/types/interfaces';
import '@vaadin/vaadin-text-field/vaadin-text-field';

export function XButtonEditor(column: RevoGrid.ColumnRegular, saveCallback: any, closeCallback: any) {
  // console.log('column', column);
  // console.log('column', column.editor);
  // console.log('column', column.cellProperties);
  // console.log('column', column.cellTemplate);
  // console.log('column', column.columnTemplate);
  // console.log('column', column.name);
  // console.log('saveCallback', saveCallback);
  // console.log('closeCallback', closeCallback);

  return {
    element: null as any, // will be setup up after render
    // element: (e: any) => {
    //   console.log('element', e);
    //   return null;
    // },
    editCell: null as any, // will be setup up after render
    // editCell: (e: any) => {
    //   console.log('editCell', e);
    //   return null;
    // }, // will be setup up after render
    /**
     * required, define custom component structure
     * @param createElement: (tagName: string, properties?: object, value?: any, children: Array) => VNode
     */
    render(createElement: RevoGrid.HyperFunc<any>) {
      // console.log('this.element', this.element); // null
      // console.log('this.editCell', this.editCell); // {x: 0, y: 9, val: "9", prop: 1, rowIndex: 9, …}
      // console.log('createElement', createElement);

      // const wrapper = createElement('span');
      // console.log(wrapper);

      const wrapper2 = document.createElement('span');
      console.log(wrapper2);

      return createElement('vaadin-text-field', {
        value: this.editCell!.val,
        onkeydown: (e: any) => {
          if (e.key === 'Enter') {
            // console.log('EEE');
            saveCallback(`MOD-${this.editCell!.val}`);
            // closeCallback((e: any) => e);
          }
        },
        autofocus: 'autofocus',
        style: {
          height: 20,
          // backgroundColor: 'yellow',
        },
      });

      // @ts-ignore
      // return ReactDOM.render(1111111, wrapper2);
    },
    componentDidRender() {}, // optional, called after component rendered
    disconnectedCallback(e: any) {
      saveCallback(`MOD-${this.editCell!.val}`);
      // saveCallback(`MOD-${this.editCell!.val}`);
    }, // optional, called after component destroyed
  };
}
